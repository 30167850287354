import React from "react";
import App from "../components/App";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import PageBuilder from "../components/PageBuilder";
import SEO from "../components/SEO";

const GET_MARKETING_SECTIONS = graphql`
  query {
    allContentfulPage(filter: { page: { eq: "Marketing" } }) {
      edges {
        node {
          section {
            ... on ContentfulSection {
              sectionHeading: heading
              contentfulDescription: description {
                description
              }
              displayGooglePartnerBadge
              usePartnerBadges
              sectionImage: image {
                gatsbyImageData
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              fullWidth
              noMargin
              secondaryHeading
              buttonLink
              buttonText
              buttonVariant
              paragraphFontSize
              reverse
              pageLink
              mobile
              backgroundColor
              icons {
                gatsbyImageData
              }
            }
            ... on ContentfulTestimonials {
              quote {
                quote
              }
              image {
                gatsbyImageData
                description
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulHero {
              heroDescription: description
              heroHeading: heading
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulClientSection {
              backgroundColor
              logo {
                gatsbyImageData
                description
              }
              image {
                gatsbyImageData
                description
              }
              reverse
              paragraph: description {
                description
              }
            }
          }
          title
          description
          page
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const Marketing = ({ location }: PageProps) => {
  const marketingSections = useStaticQuery(GET_MARKETING_SECTIONS);
  const {
    section: data,
    image,
    title,
    description
  } = marketingSections?.allContentfulPage?.edges?.[0]?.node;
  return (
    <App location={location}>
      <SEO
        title={title}
        description={description}
        url={location.href}
        image={image?.file?.url}
      />
      <PageBuilder data={data} />
    </App>
  );
};

export default Marketing;
